import { Nullable } from "src/core/shared/types/nullable.type";

export type MetadataPrimitives = {
  steps: Array<StepMetadata>
}

export type StepMetadata = {
  stepId: string,
  percentage: number,
  isDownloaded: boolean,
  isSelected: boolean,
};

export class ExperienceMetadata {
  constructor(public readonly experienceId: string, public readonly language: string, public metadata: MetadataPrimitives) {}

  static new(experienceId: string, language: string) {
    return new this(experienceId, language, { steps: [] } as MetadataPrimitives);
  }

  toPrimitives() {
    return {
      experienceId: this.experienceId,
      language: this.language,
      metadata: this.metadata,
    }
  }

  recordStepProgress(stepId: string, percentage: number) {
    const index = this.metadata.steps.findIndex(step => step.stepId === stepId);
    if (index !== -1) {
      this.metadata.steps[index].percentage = percentage;
      this.metadata.steps[index].isDownloaded = percentage === 100;
    } else {
      let stepMetadata = this.defaultStepMetadata(stepId);
      stepMetadata.percentage = percentage
      stepMetadata.isDownloaded = percentage === 100;
      this.metadata.steps.push(stepMetadata);
    }
  }

  recordStepSelected(stepId: Nullable<string>) {
    this.metadata.steps.map(step => step.isSelected = false);
    if (stepId !== null) {
      const index = this.metadata.steps.findIndex(step => step.stepId === stepId);
      if (index !== -1) {
        this.metadata.steps[index].isSelected = true;
      }
    }
  }

  getSelectedStepId(): Nullable<string> {
    const selectedStep = this.metadata.steps.find(step => step.isSelected);
    if (!selectedStep) {
      return null;
    }

    return selectedStep.stepId;
  }

  isStepLoaded(stepId: string): boolean {
    const stepMetadata = this.metadata.steps.find(step => step.stepId === stepId);
    if (!stepMetadata) {
      return false;
    }

    return stepMetadata.isDownloaded;
  }

  private defaultStepMetadata(stepId: string) {
    return {
      stepId,
      percentage: 0,
      isDownloaded: false,
      isSelected: false,
    };
  }
}
