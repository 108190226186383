export type MultiLanguageStringPrimitives = { [key: string]: string; };

export class MultiLanguageString {
  constructor(private readonly strings: MultiLanguageStringPrimitives) {}

  get(language: string): string {
    return this.strings[language] ?? '';
  }

  toPrimitives(): MultiLanguageStringPrimitives {
    return this.strings;
  }
}
