import { Nullable } from "src/core/shared/types/nullable.type";

export type UserInfoPrimitives = {
  firstName: string;
  lastName: string;
  currentPoints: number;
  completedMissions: Array<CompletedMission>;
  redeemedPrizes: Array<RedeemedPrize>;
};

export type CompletedMission = {
  id: string;
  points: number;
}

export type RedeemedPrize = {
  id: string;
  points: number;
}

export class UserInfo {
  constructor(
    public readonly firstName: string,
    public readonly lastName: string,
    public currentPoints: number,
    public readonly completedMissions: Array<CompletedMission>,
    public readonly redeemedPrizes: Array<RedeemedPrize>,
  ) {}

  static fromPrimitives(primitives: UserInfoPrimitives): Nullable<UserInfo> {
    if (!primitives || !primitives.firstName) {
      return null;
    }

    return new this(
      primitives.firstName,
      primitives.lastName,
      primitives.currentPoints,
      primitives.completedMissions,
      primitives.redeemedPrizes,
    );
  }

  toPrimitives(): UserInfoPrimitives {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      currentPoints: this.currentPoints,
      completedMissions: this.completedMissions,
      redeemedPrizes: this.redeemedPrizes,
    };
  }

  isMissionCompleted(missionId: string): boolean {
    const completedMissionIds = this.completedMissions.map(o => o.id);
    return completedMissionIds.includes(missionId);
  }

  getPointsForMission(missionId: string): number {
    const mission = this.completedMissions.find(o => o.id === missionId);
    if (!mission) {
      return 0;
    }

    return mission.points;
  }

  isPrizeRedeemed(prizeId: string): boolean {
    return this.redeemedPrizes.map(o => o.id).includes(prizeId);
  }

  getPointsForPrize(prizeId: string): number {
    const prize = this.redeemedPrizes.find(o => o.id === prizeId);
    if (!prize) {
      return 0;
    }

    return prize.points;
  }

  addRedeemedPrize(prizeId: string, points: number) {
    if (this.currentPoints < points) {
      return;
    }

    this.redeemedPrizes.push({
      id: prizeId,
      points,
    });

    this.currentPoints -= points;
  }

  addCompletedMission(missionId: string, points: any) {
    this.completedMissions.push({
      id: missionId,
      points,
    });

    this.currentPoints += points;
  }
}
