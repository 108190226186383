import { ExtraContent } from "src/core/extra-content/domain/extra-content";
import { ExtraContentRepository } from "src/core/extra-content/domain/extra-content.repository";
import { ApiService } from "src/core/api/domain/api.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SessionService } from "src/core/session/domain/session.service";
import { lastValueFrom } from "rxjs";
import { ApiGetExtraContentsResponse } from "src/core/extra-content/infrastructure/api-get-extra-contents-response";
import { Injectable } from "@angular/core";
import { ExtraContents } from "src/core/extra-content/domain/extra-contents";
import { ApiAccessService } from "src/core/api/domain/api-access.service";

@Injectable({
  providedIn: 'any',
})
export class HttpExtraContentRepository extends ExtraContentRepository {
  constructor(
    private readonly apiService: ApiService,
    private readonly apiAccessService: ApiAccessService,
    private readonly http: HttpClient,
    private readonly sessionService: SessionService,
  ) {
    super();
  }

  async load(language: string): Promise<ExtraContents> {
    try {
      const apiResponse = await this.loadResponse(language);
      return this.parseApiLoadResponse(apiResponse);
    } catch (exception) {
      return Promise.reject(exception);
    }
  }

  private async loadResponse(language: string): Promise<ApiGetExtraContentsResponse> {
    await this.apiAccessService.renewApiAccessIfNeeded();

    const baseUrl = this.apiService.currentApi().urlMobile;
    const apikey = this.apiService.currentApi().apiKeyMobile;
    const apiToken = this.sessionService.getApiToken();

    const url = `${baseUrl}/extraContents/${language}`;
    const apiVersion = '1.0';
    const appVersion = '1.0';
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Accept', 'text/plain');
    httpHeaders = httpHeaders.set('X-API-KEY', apikey);
    httpHeaders = httpHeaders.set('X-APP-VERSION', appVersion);
    httpHeaders = httpHeaders.set('X-API-VERSION', apiVersion);
    httpHeaders = httpHeaders.set('Authorization', `Bearer ${apiToken?.token ?? '--'}`);

    const options = {
      headers: httpHeaders,
    };

    return await lastValueFrom(this.http.get<ApiGetExtraContentsResponse>(url, options));
  }

  private parseApiLoadResponse(response: ApiGetExtraContentsResponse): ExtraContents {
    const extraContents = response.extraContents.map(extraContentData => {
      const description = {
        'es': 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad autem eaque expedita fugiat harum incidunt laborum nesciunt, quas totam voluptas.',
      };

      return ExtraContent.fromPrimitives({
        id: extraContentData.id,
        type: extraContentData.type,
        order: extraContentData.order,
        title: extraContentData.name,
        description,
        imageUrl: extraContentData.imageUrl,
        contentId: extraContentData.contentId,
        media: extraContentData.media,
      });
    }).sort((a: ExtraContent, b: ExtraContent) => (a.order - b.order));

    return new ExtraContents(extraContents);
  }
}
