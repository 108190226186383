export type ExtraContentPrimitives = {
  id: string;
  type: number;
  order: number;
  title: { [key: string]: string; };
  description: { [key: string]: string; };
  imageUrl: string;
  contentId: string;
  media: {
    type: string;
    url: string;
    name: string;
  };
}

export class ExtraContent {
  public static readonly INFORMATIVE_TYPE = 1;
  public static readonly EXCLUSIVE_TYPE = 2;

  constructor(
    public readonly id: string,
    public readonly type: number,
    public readonly order: number,
    public readonly title: { [key: string]: string; },
    public readonly description: { [key: string]: string; },
    public readonly imageUrl: string,
    public readonly contentId: string,
    public readonly media: {
      type: string,
      url: string,
      name: string,
    },
  ) {}

  static fromPrimitives(mission: ExtraContentPrimitives) {
    return new ExtraContent(
      mission.id,
      mission.type,
      mission.order,
      mission.title,
      mission.description,
      mission.imageUrl,
      mission.contentId,
      mission.media,
    );
  }

  getTitle(language: string): string {
    return this.title[language] ?? '';
  }

  getDescription(language: string): string {
    return this.description[language] ?? '';
  }

  toPrimitives(): ExtraContentPrimitives {
    return {
      id: this.id,
      type: this.type,
      order: this.order,
      title: this.title,
      description: this.description,
      imageUrl: this.imageUrl,
      contentId: this.contentId,
      media: this.media,
    };
  }

  isInformative(): boolean {
    return this.type === ExtraContent.INFORMATIVE_TYPE;
  }

  isExclusive(): boolean {
    return this.type === ExtraContent.EXCLUSIVE_TYPE;
  }
}
