import { Experiences } from "src/core/experiences/domain/experiences";
import { Initializable } from "src/core/shared/initializer/domain/initializable";
import { Storable } from "src/core/shared/storage/domain/storable";
import { BehaviorSubject, Observable } from "rxjs";
import { StorageService } from "src/core/shared/storage/domain/storage.service";
import { Experience, ExperiencePrimitives } from "src/core/experiences/domain/experience";
import { Nullable } from "src/core/shared/types/nullable.type";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class ExperienceService implements Storable, Initializable {
  private readySubject = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  ready$: Observable<boolean> = this.readySubject.asObservable();

  private readonly KEY_EXPERIENCES = 'experiences';

  private experiences: Nullable<Experiences> = null;

  constructor(private storage: StorageService) {}

  async init() {
    await this.initFromStorage();

    this.readySubject.next(true);
  }

  async clear() {
    await this.clearExperiences();
  }

  async saveExperiences(experiences: Experiences) {
    this.experiences = experiences;
    const experiencesPrimitives = experiences.toPrimitives();
    await this.storage.set(this.KEY_EXPERIENCES, experiencesPrimitives);
  }


  getExperience(id: string): Nullable<Experience> {
    if (!this.experiences) {
      return null;
    }

    return this.experiences.findById(id);
  }

  getAll(): Experiences {
    return this.experiences ?? new Experiences();
  }

  private async initFromStorage(): Promise<void> {
    await this.initExperiences();
  }

  private async initExperiences() {
    const experiences = await this.storage.get(this.KEY_EXPERIENCES);
    this.experiences = experiences
      ? new Experiences(experiences.map((experience: ExperiencePrimitives) => Experience.fromPrimitives(experience)))
      : new Experiences();
  }

  private async clearExperiences() {
    this.experiences = null;
    await this.storage.remove(this.KEY_EXPERIENCES);
  }
}
