import { Nullable } from "src/core/shared/types/nullable.type";

export type MissionPrimitives = {
  id: string;
  type: number;
  title: { [key: string]: string; };
  shortDescription: { [key: string]: string; };
  longDescription: { [key: string]: string; };
  points: number;
  order: number;
  imageUrl: string;
  contentId: Nullable<string>;
  quizId: Nullable<string>;
}

export class Mission {
  public static readonly TYPE_BUYER = 1;
  public static readonly TYPE_COLLEAGUE = 2;
  public static readonly TYPE_CONTENT = 3;
  public static readonly TYPE_QUIZ = 4;

  constructor(
    public readonly id: string,
    public readonly type: number,
    public readonly order: number,
    public readonly title: { [key: string]: string; },
    public readonly shortDescription: { [key: string]: string; },
    public readonly longDescription: { [key: string]: string; },
    public readonly points: number,
    public readonly imageUrl: string,
    public readonly contentId: Nullable<string>,
    public readonly quizId: Nullable<string>,
  ) {}

  static fromPrimitives(mission: MissionPrimitives) {
    return new Mission(
      mission.id,
      mission.type,
      mission.order,
      mission.title,
      mission.shortDescription,
      mission.longDescription,
      mission.points,
      mission.imageUrl,
      mission.contentId,
      mission.quizId,
    );
  }

  getTitle(language: string): string {
    return this.title[language] ?? '';
  }

  getShortDescription(language: string): string {
    return this.shortDescription[language] ?? '';
  }

  getLongDescription(language: string): string {
    return this.longDescription[language] ?? '';
  }

  toPrimitives(): MissionPrimitives {
    return {
      id: this.id,
      type: this.type,
      order: this.order,
      title: this.title,
      shortDescription: this.shortDescription,
      longDescription: this.longDescription,
      imageUrl: this.imageUrl,
      points: this.points,
      contentId: this.contentId,
      quizId: this.quizId,
    };
  }

  isTypeQuiz(): boolean {
    return this.type === Mission.TYPE_QUIZ;
  }

  isTypeColleague(): boolean {
    return this.type === Mission.TYPE_COLLEAGUE;
  }

  isTypeContent(): boolean {
    return this.type === Mission.TYPE_CONTENT;
  }

  isTypeBuyer(): boolean {
    return this.type === Mission.TYPE_BUYER;
  }
}
