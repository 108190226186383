import { Injectable } from '@angular/core';
import { ExperienceRepository } from "../domain/experience.repository";
import { Experience } from "../domain/experience";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiGetExperiencesResponse } from "./api-get-experiences-response";
import { lastValueFrom } from "rxjs";
import { SessionService } from "../../session/domain/session.service";
import { Experiences } from "src/core/experiences/domain/experiences";
import { ExperienceStep } from "src/core/experiences/domain/experience-step";
import { LocalDate } from "src/core/shared/date/local-date";
import { ApiService } from "src/core/api/domain/api.service";
import { ApiAccessService } from "src/core/api/domain/api-access.service";
import moment from "moment-timezone";

@Injectable({
  providedIn: 'any',
})
export class HttpExperienceRepository extends ExperienceRepository {
  constructor(
    private readonly apiService: ApiService,
    private readonly apiAccessService: ApiAccessService,
    private readonly http: HttpClient,
    private readonly sessionService: SessionService,
  ) {
    super();
  }

  async load(): Promise<Experiences> {
    const language = this.sessionService.currentLanguage();
    const apiResponse = await this.loadResponse(language);
    return new Experiences(this.parseApiLoadResponse(apiResponse));
  }

  private async loadResponseMocked(language: string): Promise<ApiGetExperiencesResponse> {
    return await lastValueFrom(this.http.get('/assets/api-mocked/get-experiences.response.json')) as ApiGetExperiencesResponse;
  }

  private async loadResponse(language: string): Promise<ApiGetExperiencesResponse> {
    await this.apiAccessService.renewApiAccessIfNeeded();

    const baseUrl = this.apiService.currentApi().urlMobile;
    const apikey = this.apiService.currentApi().apiKeyMobile;
    const url = `${baseUrl}/experiences`;
    const apiToken = this.sessionService.getApiToken();
    const appVersion = '1.0'; // set to 1.0.0 when fixed in the API
    const apiVersion = '1.0';
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('X-API-KEY', apikey);
    httpHeaders = httpHeaders.set('X-LANGUAGE', language);
    httpHeaders = httpHeaders.set('X-APP-VERSION', appVersion);
    httpHeaders = httpHeaders.set('X-API-VERSION', apiVersion);
    httpHeaders = httpHeaders.set('Authorization', `Bearer ${apiToken?.token ?? '--'}`);
    const options = { headers: httpHeaders };

    return await lastValueFrom(this.http.get<ApiGetExperiencesResponse>(url, options));
  }

  private parseApiLoadResponse(response: ApiGetExperiencesResponse): Experience[] {
    return response.experiences.map(experienceData => {
      const steps = experienceData.steps.map(step => {
        return ExperienceStep.fromPrimitives(step);
      }).sort((a, b) => a.order - b.order);

      // hack to always be updated, in order to force deleting
      // experienceData.updateAt = moment().format();

      return new Experience(
        experienceData.id,
        experienceData.name,
        experienceData.availableLanguages,
        steps,
        LocalDate.fromIsoString(experienceData.updateAt),
      );
    });
  }
}
