import { Nullable } from "src/core/shared/types/nullable.type";
import { Mission, MissionPrimitives } from "src/core/mission/domain/mission";

export class Missions {
  constructor(public missions: Array<Mission> = []) {}

  toShowInHomePage(): Missions {
    return new Missions(this.missions.filter(m => !m.isTypeQuiz() && !m.isTypeColleague() && !m.isTypeBuyer()));
  }

  notQuiz(): Missions {
    return new Missions(this.missions.filter(m => !m.isTypeQuiz()));
  }

  toArray(): Mission[] {
    return this.missions;
  }

  findById(id: string): Nullable<Mission> {
    const missions = this.missions.filter(mission => mission.id === id);
    if (missions.length === 0) {
      return null;
    }

    return missions[0];
  }

  toPrimitives(): Array<MissionPrimitives> {
    return this.missions.map(mission => mission.toPrimitives());
  }

  ids(): Array<string> {
    return this.missions.map(mission => mission.id);
  }
}
