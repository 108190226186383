import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { StepSelectedEvent } from './step-selected.event';
import { AudioDownloadedEvent } from "src/core/events/audio-downloaded.event";
import { UserLoggedInEvent } from "src/core/events/user-logged-in.event";
import { UserLoggedOutEvent } from "src/core/events/user-logged-out.event";
import { CurrentPointsChangedEvent } from "src/core/events/current-points-changed.event";
import { ChangeTabsVisibilityEvent } from "src/core/events/change-tabs-visibility.event";
import { EnteredVideoPlayerEvent } from "src/core/events/entered-video-player.event";
import { ExitedVideoPlayerEvent } from "src/core/events/exited-video-player.event";
import { MissionCompletedEvent } from "src/core/events/mission-completed.event";
import { PrizeRedeemedEvent } from "src/core/events/prize-redeemed.event";

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  private stepSelectedEventSubject: Subject<StepSelectedEvent> = new Subject<StepSelectedEvent>();
  stepSelected$: Observable<StepSelectedEvent> = this.stepSelectedEventSubject.asObservable();
  private audioDownloadedEventSubject: Subject<AudioDownloadedEvent> = new Subject<AudioDownloadedEvent>();
  audioDownloaded$: Observable<AudioDownloadedEvent> = this.audioDownloadedEventSubject.asObservable();
  private loggedInEventSubject: Subject<UserLoggedInEvent> = new Subject<UserLoggedInEvent>();
  userLoggedIn$: Observable<UserLoggedInEvent> = this.loggedInEventSubject.asObservable();
  private loggedOutEventSubject: Subject<UserLoggedOutEvent> = new Subject<UserLoggedOutEvent>();
  userLoggedOut$: Observable<UserLoggedOutEvent> = this.loggedOutEventSubject.asObservable();
  private currentPointsChangedEventSubject: Subject<CurrentPointsChangedEvent> = new Subject<CurrentPointsChangedEvent>();
  currentPointsChanged$: Observable<CurrentPointsChangedEvent> = this.currentPointsChangedEventSubject.asObservable();
  private changeTabsVisibilityEventSubject: Subject<ChangeTabsVisibilityEvent> = new Subject<ChangeTabsVisibilityEvent>();
  changeTabsVisibility$: Observable<ChangeTabsVisibilityEvent> = this.changeTabsVisibilityEventSubject.asObservable();
  private enteredVideoPlayerEventSubject: Subject<EnteredVideoPlayerEvent> = new Subject<EnteredVideoPlayerEvent>();
  enteredVideoPlayer$: Observable<EnteredVideoPlayerEvent> = this.enteredVideoPlayerEventSubject.asObservable();
  private exitedVideoPlayerEventSubject: Subject<ExitedVideoPlayerEvent> = new Subject<ExitedVideoPlayerEvent>();
  exitedVideoPlayer$: Observable<ExitedVideoPlayerEvent> = this.exitedVideoPlayerEventSubject.asObservable();

  private missionCompletedEventSubject: Subject<MissionCompletedEvent> = new Subject<MissionCompletedEvent>();
  missionCompleted$: Observable<MissionCompletedEvent> = this.missionCompletedEventSubject.asObservable();
  private prizeRedeemedEventSubject: Subject<PrizeRedeemedEvent> = new Subject<PrizeRedeemedEvent>();
  prizeRedeemed$: Observable<PrizeRedeemedEvent> = this.prizeRedeemedEventSubject.asObservable();

  publishStepSelected(event: StepSelectedEvent) {
    this.stepSelectedEventSubject.next(event);
  }

  publishAudioDownloaded(event: AudioDownloadedEvent) {
    this.audioDownloadedEventSubject.next(event);
  }

  publishLoggedInEvent(event: UserLoggedInEvent): void {
    this.loggedInEventSubject.next(event);
  }

  publishLoggedOutEvent(event: UserLoggedOutEvent): void {
    this.loggedOutEventSubject.next(event);
  }

  publishCurrentPointsChangedEvent(event: CurrentPointsChangedEvent): void {
    this.currentPointsChangedEventSubject.next(event);
  }

  publishChangeTabsVisibilityEvent(event: ChangeTabsVisibilityEvent): void {
    this.changeTabsVisibilityEventSubject.next(event);
  }

  publishEnteredVideoPlayerEvent(event: EnteredVideoPlayerEvent): void {
    this.enteredVideoPlayerEventSubject.next(event);
  }

  publishExitedVideoPlayerEvent(event: ExitedVideoPlayerEvent): void {
    this.exitedVideoPlayerEventSubject.next(event);
  }

  publishMissionCompletedEvent(event: MissionCompletedEvent): void {
    this.missionCompletedEventSubject.next(event);
  }

  publishPrizeRedeemedEvent(event: PrizeRedeemedEvent): void {
    this.prizeRedeemedEventSubject.next(event);
  }
}
