import { enableProdMode, importProvidersFrom, Provider } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter, RouteReuseStrategy, withComponentInputBinding } from '@angular/router';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { TranslateLoader, TranslateModule, TranslateParser } from "@ngx-translate/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { IonicStorageModule } from "@ionic/storage-angular";
import { Drivers } from '@ionic/storage';
import { register } from "swiper/element/bundle";
import { ExperienceRepository } from "./core/experiences/domain/experience.repository";
import { HttpExperienceRepository } from "./core/experiences/infrastructure/http-experience.repository";
import { CustomTranslateParser } from "src/core/shared/translations/infrastructure/custom-translate-parser";
import { StorageService } from "src/core/shared/storage/domain/storage.service";
import { IonicStorageService } from "src/core/shared/storage/infrastructure/ionic-storage.service";
import { TranslatorService } from "src/core/shared/translations/domain/translator.service";
import { AngularTranslatorService } from "src/core/shared/translations/infrastructure/angular-translator.service";
import { StepRepository } from "src/core/step/domain/step.repository";
import { HttpStepRepository } from "src/core/step/infrastructure/http-step.repository";
import { Media } from "@awesome-cordova-plugins/media/ngx";
import { File } from "@awesome-cordova-plugins/file/ngx";
import { MissionRepository } from "src/core/mission/domain/mission.repository";
import { HttpMissionRepository } from "src/core/mission/infrastructure/http-mission.repository";
import { PrizeRepository } from "src/core/prize/domain/prize.repository";
import { HttpPrizeRepository } from "src/core/prize/infrastructure/http-prize.repository";
import { HttpExtraContentRepository } from "src/core/extra-content/infrastructure/http-extra-content.repository";
import { ExtraContentRepository } from "src/core/extra-content/domain/extra-content.repository";
import { ContentRepository } from "src/core/content/domain/content.repository";
import { HttpContentRepository } from "src/core/content/infrastructure/http-content.repository";
import { UserRepository } from "src/core/user/domain/user.repository";
import { HttpUserRepository } from "src/core/user/infrastructure/http-user.repository";
import { ScreenOrientation } from "@awesome-cordova-plugins/screen-orientation/ngx";
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { QuizRepository } from "src/core/quiz/domain/quiz.repository";
import { HttpQuizRepository } from "src/core/quiz/infrastructure/http-quiz.repository";
import { AuthRepository } from "src/core/authentication/domain/auth.repository";
import { HttpAuthRepository } from "src/core/authentication/infrastructure/http-auth.repository";

if (environment.production) {
  enableProdMode();
}

register();

export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

const msalAuth = {
  clientId: 'a1d8be04-814c-4573-a44a-8b7113fd7bbe',
  redirectUri: '/', // O tu URL de redireccionamiento
  authority: 'https://gaudib2c.b2clogin.com/gaudib2c.onmicrosoft.com/B2C_1A_A_CUSTOM_SIGNIN',
  // authority: 'https://gaudib2c.b2clogin.com/gaudib2c.onmicrosoft.com/B2C_1A_A_CUSTOM_SIGNUP',
  knownAuthorities: [ "gaudib2c.b2clogin.com" ],
  postLogoutRedirectUri: "/",
  // https://gaudib2c.b2clogin.com/gaudib2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_A_CUSTOM_SIGNIN&client_id=a1d8be04-814c-4573-a44a-8b7113fd7bbe&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fjwt.ms%2F&scope=openid&response_type=id_token&prompt=login
  // https://gaudib2c.b2clogin.com/gaudib2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_A_CUSTOM_SIGNIN&client_id=a1d8be04-814c-4573-a44a-8b7113fd7bbe&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fjwt.ms%2F&scope=openid&response_type=id_token&prompt=login
};

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Media as Provider, // Explicitly casting Media to Provider
    File as Provider, // Explicitly casting File to Provider
    provideIonicAngular(),
    provideRouter(routes, withComponentInputBinding()),
    importProvidersFrom(
      HttpClientModule,
      IonicStorageModule.forRoot({
        name: 'gpa_app' + (environment.database_name_suffix ? `_${environment.database_name_suffix}` : ''),
        driverOrder: [ Drivers.IndexedDB ],
      }),
      TranslateModule.forRoot({
        defaultLanguage: 'es', // TODO: Move to a System.service.ts::DEFAULT_LANGUAGE,
        parser: {
          provide: TranslateParser,
          useClass: CustomTranslateParser,
        },
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [ HttpClient ],
        },
      }),
    ),
    FirebaseX,
    ScreenOrientation,
    AndroidPermissions,
    {
      provide: StorageService,
      useClass: IonicStorageService,
    },
    {
      provide: TranslatorService,
      useClass: AngularTranslatorService,
    },
    {
      provide: ExperienceRepository,
      useClass: HttpExperienceRepository,
    },
    {
      provide: StepRepository,
      useClass: HttpStepRepository,
    },
    {
      provide: MissionRepository,
      useClass: HttpMissionRepository,
    },
    {
      provide: PrizeRepository,
      useClass: HttpPrizeRepository,
    },
    {
      provide: ContentRepository,
      useClass: HttpContentRepository,
    },
    {
      provide: ExtraContentRepository,
      useClass: HttpExtraContentRepository,
    },
    {
      provide: UserRepository,
      useClass: HttpUserRepository,
    },
    {
      provide: QuizRepository,
      useClass: HttpQuizRepository,
    },
    {
      provide: AuthRepository,
      useClass: HttpAuthRepository,
    },
  ],
});
