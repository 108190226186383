import { Injectable } from "@angular/core";
import { Nullable } from "src/core/shared/types/nullable.type";
import { Subscription } from "rxjs";
import { FirebaseX } from "@awesome-cordova-plugins/firebase-x/ngx";
import { PlatformService } from "src/core/platform/platform.service";
import { PrivacyPolicyService } from "src/core/privacy-policy/domain/privacy-policy.service";

@Injectable({
  providedIn: "root",
})
export class FirebaseService {
  private firebaseTokenSubscription: Nullable<Subscription> = null;
  private firebaseMessageReceivedSubscription: Nullable<Subscription> = null;

  constructor(
    private readonly firebaseX: FirebaseX,
    private readonly platformService: PlatformService,
    private readonly privacyPolicyService: PrivacyPolicyService,
  ) {}

  async init() {
    console.log('FirebaseService.init()');
    if (!this.platformService.isRealMobileDevice()) {
      console.log(' - not real mobile device, skipping');
      return;
    }

    if (!this.privacyPolicyService.isAnalyticsCookiesEnabled()) {
      console.log(' - analytics cookies not enabled, skipping');
      return;
    }

    console.log(' - configuring firebaseX…');
    try {
      // save the token server-side and use it to push notifications to this device
      const token = await this.firebaseX.getToken();
      console.log(`The token is ${token}`);
    } catch (error) {
      console.error('Error getting token', error);
    }

    // TBC: If FirebaseX didn't get a valid token, does the following code work?
    await this.enableAnalytics();
  }

  async enableAnalytics() {
    console.log('FirebaseService: enable analytics');
    await this.firebaseX.setCrashlyticsCollectionEnabled(true);
    await this.firebaseX.setAnalyticsCollectionEnabled(true);
    this.bindEvents();
  }

  async disableAnalytics() {
    console.log('FirebaseService: disable analytics');
    await this.firebaseX.setCrashlyticsCollectionEnabled(false);
    await this.firebaseX.setAnalyticsCollectionEnabled(false);
  }

  private bindEvents() {
    this.firebaseTokenSubscription = this.firebaseX.onTokenRefresh().subscribe(
      (token: string) => console.log(`Got a new token ${token}`)
    );
    this.firebaseMessageReceivedSubscription = this.firebaseX.onMessageReceived().subscribe(
      (data) => console.log(`User opened a notification ${data}`)
    );
  }

  private unbindEvents() {
    if (this.firebaseTokenSubscription) {
      this.firebaseTokenSubscription.unsubscribe();
    }
    if (this.firebaseMessageReceivedSubscription) {
      this.firebaseMessageReceivedSubscription.unsubscribe();
    }
  }
}
