import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { lastValueFrom } from "rxjs";
import { SessionService } from "../../session/domain/session.service";
import { ApiService } from "src/core/api/domain/api.service";
import { MissionRepository } from "src/core/mission/domain/mission.repository";
import { Mission } from "src/core/mission/domain/mission";
import { ApiGetMissionsResponse } from "src/core/mission/infrastructure/api-get-missions-response";
import { Missions } from "src/core/mission/domain/missions";
import { ApiAccessService } from "src/core/api/domain/api-access.service";

@Injectable({
  providedIn: 'any',
})
export class HttpMissionRepository extends MissionRepository {
  constructor(
    private readonly apiService: ApiService,
    private readonly apiAccessService: ApiAccessService,
    private readonly http: HttpClient,
    private readonly sessionService: SessionService,
  ) {
    super();
  }

  async markAsCompleted(missionId: string): Promise<void> {
    await this.apiAccessService.renewApiAccessIfNeeded();

    const baseUrl = this.apiService.currentApi().urlVisitor;
    const apiToken = this.sessionService.getApiToken();
    const url = `${baseUrl}/missions/${missionId}/complete`;
    const apiVersion = '1.0';
    const appVersion = '1.0';
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Accept', 'text/plain');
    httpHeaders = httpHeaders.set('X-APP-VERSION', appVersion);
    httpHeaders = httpHeaders.set('X-API-VERSION', apiVersion);
    httpHeaders = httpHeaders.set('Authorization', `Bearer ${apiToken?.token ?? '--'}`);
    const options = {
      headers: httpHeaders,
    };
    const body = {
      ticketId: '1234'
    };

    await lastValueFrom(this.http.post(url, body, options));
  }

  async load(): Promise<Missions> {
    try {
      const apiResponse = await this.loadResponse();
      return this.parseApiLoadResponse(apiResponse);
    } catch (exception) {
      return Promise.reject(exception);
    }
  }

  private async loadResponse(): Promise<ApiGetMissionsResponse> {
    await this.apiAccessService.renewApiAccessIfNeeded();

    const baseUrl = this.apiService.currentApi().urlMobile;
    const apikey = this.apiService.currentApi().apiKeyMobile;
    const apiToken = this.sessionService.getApiToken();
    const url = `${baseUrl}/missions`;
    const apiVersion = '1.0';
    const appVersion = '1.0';

    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set('Accept', 'text/plain');
    httpHeaders = httpHeaders.set('X-API-KEY', apikey);
    httpHeaders = httpHeaders.set('X-APP-VERSION', appVersion);
    httpHeaders = httpHeaders.set('X-API-VERSION', apiVersion);
    httpHeaders = httpHeaders.set('Authorization', `Bearer ${apiToken?.token ?? '--'}`);
    const options = {
      headers: httpHeaders,
    };

    return await lastValueFrom(this.http.get<ApiGetMissionsResponse>(url, options));
  }

  private parseApiLoadResponse(response: ApiGetMissionsResponse): Missions {
    const missions = response.missions.map(missionData => {
      // Temporal hack, when the image actually arribes, delete this line
      const imageUrl = missionData.imageUrl ?? `/assets/images/mission-type-${missionData.type}.png`;
      return Mission.fromPrimitives({
          id: missionData.id,
          type: missionData.type,
          order: missionData.order,
          title: missionData.name,
          shortDescription: missionData.shortDescription,
          longDescription: missionData.longDescription,
          imageUrl,
          points: missionData.points,
          contentId: missionData.contentId,
          quizId: missionData.quizId,
        },
      )
    }).sort((a: Mission, b: Mission) => (a.order - b.order));

    return new Missions(missions);
  }
}
