import { Nullable } from "src/core/shared/types/nullable.type";

export type PrivacyPolicyPrimitives = {
  configured: boolean;
  neededCookies: boolean;
  analyticsCookies: Nullable<boolean>;
  advertisingCookies: Nullable<boolean>;
}

export class PrivacyPolicy {
  constructor(
    private configured: boolean,
    private neededCookies: boolean,
    private analyticsCookies: Nullable<boolean>,
    private advertisingCookies: Nullable<boolean>,
  ) {}

  static fromPrimitives(primitives: PrivacyPolicyPrimitives): PrivacyPolicy {
    return new this(
      primitives.configured,
      primitives.neededCookies,
      primitives.analyticsCookies,
      primitives.advertisingCookies,
    )
  }

  static default(): PrivacyPolicy {
    return new this(false, true, null, null);
  }

  isConfigured(): boolean {
    return this.configured;
  }

  isNeededCookiesEnabled(): boolean {
    return this.neededCookies;
  }

  isAnalyticsCookiesEnabled(): Nullable<boolean> {
    return this.analyticsCookies;
  }

  isAdvertisingCookiesEnabled(): Nullable<boolean> {
    return this.advertisingCookies;
  }

  updateValues(
    configured: boolean,
    neededCookies: boolean,
    analyticsCookies: boolean,
    advertisingCookies: boolean,
  ) {
    this.configured = configured;
    this.neededCookies = neededCookies;
    this.analyticsCookies = analyticsCookies;
    this.advertisingCookies = advertisingCookies;
  }

  toPrimitives(): PrivacyPolicyPrimitives {
    return {
      configured: this.configured,
      neededCookies: this.neededCookies,
      analyticsCookies: this.analyticsCookies,
      advertisingCookies: this.advertisingCookies,
    };
  }
}
