import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { TranslatorService } from "src/core/shared/translations/domain/translator.service";

@Injectable({
  providedIn: 'root',
})
export class AngularTranslatorService extends TranslatorService {

  constructor(private readonly angularTranslateService: TranslateService) {
    super();
  }

  instant(key: string, params?: object): string {
    return this.angularTranslateService.instant(key, params);
  }

  async translate(key: string, params?: object): Promise<string> {
    return lastValueFrom(this.angularTranslateService.get(key, params));
  }

  changeLanguage(language: string): void {
    this.angularTranslateService.use(language);
  }

  language(): string {
    return this.angularTranslateService.currentLang || this.angularTranslateService.defaultLang;
  }

  defaultLanguage(): string {
    const browserLang = this.angularTranslateService.getBrowserLang();
    if (!browserLang) {
      return TranslatorService.DEFAULT_LANGUAGE;
    }

    // if browser lang is not one of the available languages, return default one
    if (!TranslatorService.AVAILABLE_LANGUAGES.find(language => language.id === browserLang)) {
      return TranslatorService.DEFAULT_LANGUAGE;
    }

    return browserLang.toLowerCase();
  }
}
