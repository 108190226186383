import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiService } from "src/core/api/domain/api.service";
import { SessionService } from "src/core/session/domain/session.service";
import { ExceptionManagerService } from "src/core/shared/exceptions/exception-manager.service";
import { UnauthorizedException } from "src/core/shared/exceptions/unauthorized-exception";
import { lastValueFrom } from "rxjs";
import { JWT } from "src/core/authentication/domain/jwt";
import { ApiRefreshTokenResponse } from "src/core/authentication/infrastructure/api-refresh-token-response";

@Injectable({
  providedIn: 'any',
})
export class ApiAccessService {
  constructor(
    private readonly http: HttpClient,
    private readonly apiService: ApiService,
    private readonly sessionService: SessionService,
    private readonly exceptionManager: ExceptionManagerService,
  ) {}

  async renewApiAccessIfNeeded() {
    const apiToken = this.sessionService.getApiToken();
    if (apiToken === null) {
      throw new UnauthorizedException('', new Error());
    }

    if (!apiToken.isExpired()) {
      return;
    }

    await this.refreshApiToken();
    console.warn(' - API access token renewed after expiration');
  }

  /**
   * https://auth0.com/docs/secure/tokens/refresh-tokens/get-refresh-tokens
   */
  private async refreshApiToken() {
    const api = this.apiService.currentApi();
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const refreshToken = this.sessionService.getRefreshToken();
    if (refreshToken === null) {
      return;
    }

    const body = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('client_id', api.clientId)
      .set('client_secret', api.clientSecret)
      .set('scope', api.scopes.join(' '))
      .set('refresh_token', refreshToken);
    const response = await lastValueFrom(this.http.post<ApiRefreshTokenResponse>(this.apiService.getAccessTokenUrl(), body.toString(), { headers }));
    const apiToken = new JWT(response.access_token);
    await this.sessionService.setApiToken(apiToken);
  }
}
