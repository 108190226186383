export type ContentPrimitives = {
  id: string;
  media: {
    type: string;
    name: string;
    url: string;
  };
}

export class Content {
  constructor(
    public readonly id: string,
    public readonly media: {
      type: string,
      name: string,
      url: string,
    },
  ) {}

  static fromPrimitives(primitives: ContentPrimitives) {
    return new Content(primitives.id, primitives.media);
  }

  toPrimitives(): ContentPrimitives {
    return {
      id: this.id,
      media: this.media,
    };
  }
}
