import { Quiz, QuizPrimitives } from "src/core/quiz/domain/quiz";
import { Nullable } from "src/core/shared/types/nullable.type";

export class Quizzes {
  constructor(public readonly quizzes: Array<Quiz> = []) {}

  toArray(): Quiz[] {
    return this.quizzes;
  }

  findById(id: string): Nullable<Quiz> {
    const prizes = this.quizzes.filter(prize => prize.id === id);
    if (prizes.length === 0) {
      return null;
    }

    return prizes[0];
  }

  toPrimitives(): Array<QuizPrimitives> {
    return this.quizzes.map(prize => prize.toPrimitives());
  }

  ids(): Array<string> {
    return this.quizzes.map(prize => prize.id);
  }
}
