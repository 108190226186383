import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { GetAccessToken } from "src/core/authentication/application/get-access-token/get-access.token";
import { InitializerService } from "src/core/shared/initializer/domain/initializer.service";
import { ApiService } from "src/core/api/domain/api.service";

export const checkAuthorizationCodeInUrlGuard: CanActivateFn = async () => {
  const router = inject(Router);
  const getAccessToken = inject(GetAccessToken);
  const apiService = inject(ApiService);

  const url = window.location.href;
  const codeMatch = url.match(/code=([^&]+)/);
  if (!codeMatch) {
    return true;
  }

  const authorizationCode = codeMatch[1];
  // console.log(`Found Authorization Code in URL <${authorizationCode}>, storing in local database`);

  await InitializerService.waitFor(apiService);
  await getAccessToken.execute(authorizationCode);

  await router.navigate([ '/' ]);
  return true;
};
