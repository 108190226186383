import { Initializable } from "src/core/shared/initializer/domain/initializable";
import { Storable } from "src/core/shared/storage/domain/storable";
import { BehaviorSubject, Observable } from "rxjs";
import { StorageService } from "src/core/shared/storage/domain/storage.service";
import { Nullable } from "src/core/shared/types/nullable.type";
import { Injectable } from "@angular/core";
import { Missions } from "src/core/mission/domain/missions";
import { Mission, MissionPrimitives } from "src/core/mission/domain/mission";

@Injectable({
  providedIn: 'root',
})
export class MissionService implements Storable, Initializable {
  private readySubject = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  ready$: Observable<boolean> = this.readySubject.asObservable();

  private readonly KEY_MISSIONS = 'missions';

  private missions: Nullable<Missions> = null;

  constructor(private storage: StorageService) {}

  async init() {
    await this.initFromStorage();

    this.readySubject.next(true);
  }

  async clear() {
    await this.clearMissions();
  }

  async saveMissions(missions: Missions) {
    this.missions = missions;
    const missionsPrimitives = missions.toPrimitives();
    await this.storage.set(this.KEY_MISSIONS, missionsPrimitives);
  }


  getMission(id: string): Nullable<Mission> {
    if (!this.missions) {
      return null;
    }

    return this.missions.findById(id);
  }

  getAll(): Missions {
    return this.missions ?? new Missions();
  }

  private async initFromStorage(): Promise<void> {
    await this.initMissions();
  }

  private async initMissions() {
    const missions = await this.storage.get(this.KEY_MISSIONS);
    this.missions = missions
      ? new Missions(missions.map((mission: MissionPrimitives) => Mission.fromPrimitives(mission)))
      : new Missions();
  }

  private async clearMissions() {
    this.missions = null;
    await this.storage.remove(this.KEY_MISSIONS);
  }
}
