import { Step } from "src/core/step/domain/step";
import { StepMedia } from "src/core/step/domain/step-media";
import { StepImage } from "src/core/step/domain/step-image";

export abstract class StepRepository {
  abstract load(experienceId: string, stepId: string, language: string): Promise<Step>;

  abstract loadMedia(url: string, stepId: string, language: string, progressCallback: (stepId: string, percentage: number) => void): Promise<StepMedia>;

  abstract loadImage(url: string, stepId: string): Promise<StepImage>;
}
