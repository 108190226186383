import { Nullable } from "src/core/shared/types/nullable.type";
import {
  MultiLanguageString,
  MultiLanguageStringPrimitives,
} from "src/core/shared/multi-language/multi-language-string";
import { QuizAnswer, QuizAnswerPrimitives } from "src/core/quiz/domain/quiz-answer";

export type QuizQuestionPrimitives = {
  id: string;
  text: MultiLanguageStringPrimitives;
  answers: Array<QuizAnswerPrimitives>;
};

export class QuizQuestion {
  constructor(
    public readonly id: string,
    public readonly text: MultiLanguageString,
    public readonly answers: Array<QuizAnswer>,
  ) {}

  static fromPrimitives(primitives: QuizQuestionPrimitives): Nullable<QuizQuestion> {
    if (!primitives) {
      return null;
    }

    const answers = primitives.answers.map(o => {
      return QuizAnswer.fromPrimitives({
        id: o.id,
        text: o.text,
        isValid: o.isValid,
      });
    }).filter((q): q is QuizAnswer => q !== null);

    return new this(
      primitives.id,
      new MultiLanguageString(primitives.text),
      answers,
    );
  }

  toPrimitives(): QuizQuestionPrimitives {
    return {
      id: this.id,
      text: this.text.toPrimitives(),
      answers: this.answers.map(o => o.toPrimitives()),
    };
  }
}
