import { Initializable } from "src/core/shared/initializer/domain/initializable";
import { Storable } from "src/core/shared/storage/domain/storable";
import { BehaviorSubject, Observable } from "rxjs";
import { StorageService } from "src/core/shared/storage/domain/storage.service";
import { Nullable } from "src/core/shared/types/nullable.type";
import { Injectable } from "@angular/core";
import { Quiz, QuizPrimitives } from "src/core/quiz/domain/quiz";
import { Quizzes } from "src/core/quiz/domain/quizzes";

@Injectable({
  providedIn: 'root',
})
export class QuizService implements Storable, Initializable {
  private readySubject = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  ready$: Observable<boolean> = this.readySubject.asObservable();

  private readonly KEY_QUIZZES = 'quizzes';

  private quizzes: Nullable<Quizzes> = null;

  constructor(private storage: StorageService) {}

  async init() {
    await this.initFromStorage();

    this.readySubject.next(true);
  }

  async clear() {
    await this.clearQuizzes();
  }

  async saveQuizzes(quizzes: Quizzes) {
    this.quizzes = quizzes;
    const quizzesPrimitives = quizzes.toPrimitives();
    await this.storage.set(this.KEY_QUIZZES, quizzesPrimitives);
  }

  getQuiz(id: string): Nullable<Quiz> {
    if (!this.quizzes) {
      return null;
    }

    return this.quizzes.findById(id);
  }

  getAll(): Quizzes {
    return this.quizzes ?? new Quizzes();
  }

  private async initFromStorage(): Promise<void> {
    await this.initQuizzes();
  }

  private async initQuizzes() {
    const quizzes = await this.storage.get(this.KEY_QUIZZES);
    this.quizzes = quizzes
      ? new Quizzes(quizzes.map((primitives: QuizPrimitives) => Quiz.fromPrimitives(primitives)))
      : new Quizzes();
  }

  private async clearQuizzes() {
    this.quizzes = null;
    await this.storage.remove(this.KEY_QUIZZES);
  }
}
