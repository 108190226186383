import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SessionService } from "src/core/session/domain/session.service";

export const redirectIfLoggedGuard: CanActivateFn = async () => {
  const sessionService = inject(SessionService);
  const router = inject(Router);

  const logged = await sessionService.isAuthenticatedPromise();
  if (logged) {
    await router.navigate([ '/' ]);
    return false;
  } else {
    return true;
  }
};
