import { Component } from '@angular/core';
import { IonApp, IonRouterOutlet, Platform } from '@ionic/angular/standalone';
import { PlatformService } from "src/core/platform/platform.service";
import { StorageService } from "src/core/shared/storage/domain/storage.service";
import { UserLoggedOutEvent } from "src/core/events/user-logged-out.event";
import { EventsService } from "src/core/events/events.service";
import { Router } from "@angular/router";
import { Nullable } from "src/core/shared/types/nullable.type";
import { Subscription } from "rxjs";
import { ScreenOrientation } from "@awesome-cordova-plugins/screen-orientation/ngx";
import { EnteredVideoPlayerEvent } from "src/core/events/entered-video-player.event";
import { ExitedVideoPlayerEvent } from "src/core/events/exited-video-player.event";
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { InitializerService } from "src/core/shared/initializer/domain/initializer.service";
import { SystemService } from "src/core/system/domain/system.service";
import { FirebaseService } from "src/core/firebase/firebase.service";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [ IonApp, IonRouterOutlet ],
})
export class AppComponent {
  private userLoggedOut$: Nullable<Subscription> = null;
  private enteredVideoPlayer$: Nullable<Subscription> = null;
  private exitedVideoPlayer$: Nullable<Subscription> = null;

  constructor(
    private readonly androidPermissions: AndroidPermissions,
    private readonly firebaseService: FirebaseService,
    private readonly platformService: PlatformService,
    private readonly platform: Platform,
    private readonly storageService: StorageService,
    private readonly eventsService: EventsService,
    private readonly router: Router,
    private readonly screenOrientation: ScreenOrientation,
    private readonly initializerService: InitializerService,
    private readonly systemService: SystemService,
  ) {
    this.initializeApp().then().catch();
  }

  async initializeApp() {
    await this.platform.ready();
    this.printCurrentPlatformInfo();

    await this.bindEvents();
    await this.lockScreenOrientationToPortrait();
    await this.configureCordova();
    await this.storageService.ready();
    await this.resetAllStorageIfNeeded();

    // initialize all services that have storable info, to be ready synchronously
    await this.initializerService.initAll();

    await this.systemService.storeCurrentVersionToStorage();
  }

  private async resetAllStorageIfNeeded() {
    const needsToBeReset = await this.systemService.storageNeedsToBeReset();
    if (!needsToBeReset) {
      return;
    }

    await this.systemService.resetStorage();
    window.location.reload();
  }

  private async lockScreenOrientationToPortrait() {
    if (!this.platformService.isAndroid()) {
      return;
    }

    await this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
  }

  /**
   * The platform is ready and our plugins are available.
   * Here you can do any higher level native things you might need.
   */
  private async configureCordova(): Promise<void> {
    if (!this.platform.is('cordova')) {
      return;
    }

    await this.configureCameraPermissions();

    // Fatal native crashes are reported to Firebase.
    // Other errors are reported to Sentry using the error handler class, since it allows better debugging.
    await this.firebaseService.init();
  }

  private async configureCameraPermissions() {
    if (this.platformService.isAndroid()) {
      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA).then(
        async (result) => {
          console.log('Has CAMERA permission?', result.hasPermission)
          if (!result.hasPermission) {
            await this.requestCameraPermission();
          } else {
            console.log('Already have the CAMERA permission');
          }
        },
        async (error) => {
          console.log('Error checking permission', error);
          await this.requestCameraPermission();
        },
      );
    }
  }

  private async requestCameraPermission() {
    console.log('Requesting CAMERA permission');
    const hasPermission = await this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA);
    console.log('Has acquired permission?', hasPermission);
  }

  private printCurrentPlatformInfo(): void {
    // eslint-disable-next-line no-console
    console.info(`%c Platform: ${this.platform.platforms().join(', ')}`, 'background: #222; color: #bada55');
  }

  private async bindEvents() {
    this.userLoggedOut$ = this.eventsService.userLoggedOut$.subscribe(async (event: UserLoggedOutEvent) => {
      await this.router.navigate([ '/wizard' ]);
    });

    if (this.platformService.isAndroid()) {
      this.enteredVideoPlayer$ = this.eventsService.enteredVideoPlayer$.subscribe(async (event: EnteredVideoPlayerEvent) => {
        this.screenOrientation.unlock();
      });
      this.exitedVideoPlayer$ = this.eventsService.exitedVideoPlayer$.subscribe(async (event: ExitedVideoPlayerEvent) => {
        await this.lockScreenOrientationToPortrait();
      });
    }
  }
}
