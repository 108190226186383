import { Nullable } from "src/core/shared/types/nullable.type";
import {
  MultiLanguageString,
  MultiLanguageStringPrimitives,
} from "src/core/shared/multi-language/multi-language-string";

export type QuizAnswerPrimitives = {
  id: string;
  text: MultiLanguageStringPrimitives;
  isValid: boolean;
};

export class QuizAnswer {
  constructor(
    public readonly id: string,
    public readonly text: MultiLanguageString,
    public readonly isValid: boolean,
  ) {}

  static fromPrimitives(primitives: QuizAnswerPrimitives): Nullable<QuizAnswer> {
    if (!primitives) {
      return null;
    }

    return new this(
      primitives.id,
      new MultiLanguageString(primitives.text),
      primitives.isValid,
    );
  }

  toPrimitives(): QuizAnswerPrimitives {
    return {
      id: this.id,
      text: this.text.toPrimitives(),
      isValid: this.isValid,
    };
  }
}
