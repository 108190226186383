import { AuthRepository } from "src/core/authentication/domain/auth.repository";
import { Injectable } from "@angular/core";
import { LoggedUser } from "src/core/user/domain/logged-user";
import { SessionService } from "src/core/session/domain/session.service";
import { JWT } from "src/core/authentication/domain/jwt";

@Injectable({
  providedIn: "any",
})
export class GetAccessToken {
  constructor(private readonly authRepository: AuthRepository, private readonly sessionService: SessionService) {}

  async execute(authorizationCode: string) {
    const response = await this.authRepository.getAccessToken(authorizationCode);

    await this.sessionService.setApiToken(response.apiToken);
    await this.sessionService.setRefreshToken(response.refreshToken);
    await this.sessionService.setLoggedUser(this.getUserFromApiToken(response));
  }

  private getUserFromApiToken(response: { apiToken: JWT; refreshToken: string }) {
    return LoggedUser.fromPrimitives({
      fullName: response.apiToken.name(),
      email: response.apiToken.email(),
    });
  }
}
