import { Injectable } from '@angular/core';
import { File, FileEntry } from '@awesome-cordova-plugins/file/ngx';
import { Nullable } from "src/core/shared/types/nullable.type";

@Injectable({
  providedIn: 'root',
})
export class FilesystemService {
  constructor(private readonly file: File) {}

  async createFileInFolder(content: Blob, filename: string, folder: string): Promise<void> {
    const created = await this.ensureDirectoryIsCreated(this.file.dataDirectory, folder);
    if (!created) {
      throw new Error('Destination directory cannot be created');
    }

    await this.file.writeFile(this.file.dataDirectory + folder + '/', filename, content, { replace: true });
  }

  async copyFileToFolder(inputFilePath: string, folder: string): Promise<FileEntry> {
    const sourceDirectory = this.getFolder(inputFilePath);
    const sourceFilename = this.getFilename(inputFilePath);
    const destinationFilename = sourceFilename;
    const destinationDirectory = this.file.dataDirectory + folder;
    const created = await this.ensureDirectoryIsCreated(this.file.dataDirectory, folder);
    if (!created) {
      throw new Error('Destination directory cannot be created');
    }

    try {
      const entry = await this.file.copyFile(
        sourceDirectory,
        sourceFilename,
        destinationDirectory,
        destinationFilename,
      );
      return entry as FileEntry;
    } catch (error) {
      throw error;
    }
  }

  async ensureDirectoryIsCreated(baseDirectory: string, directory: string): Promise<boolean> {
    try {
      await this.file.checkDir(baseDirectory, directory);
      return true;
    } catch (error) {
      try {
        await this.file.createDir(baseDirectory, directory, false);
        return true;
      } catch (_error) {
        return false;
      }
    }
  }

  async fileExists(folder: string, filename: string): Promise<boolean> {
    try {
      if (!folder.endsWith('/')) {
        folder = folder + '/';
      }
      return await this.file.checkFile(this.file.dataDirectory + folder, filename);
    } catch (error) {
    }

    return false;
  }

  async readAsDataURL(filePath: string): Promise<string> {
    const folder = this.getFolder(filePath);
    const filename = this.getFilename(filePath);

    return await this.file.readAsDataURL(folder, filename);
  }

  async readAsBinaryURL(filePath: string): Promise<string> {
    const folder = this.getFolder(filePath);
    const filename = this.getFilename(filePath);

    return await this.file.readAsBinaryString(folder, filename);
  }

  async deleteDirectory(directory: string): Promise<void> {
    try {
      const baseDirectory = this.file.dataDirectory;
      await this.file.checkDir(baseDirectory, directory);
      await this.file.removeRecursively(baseDirectory, directory);
    } catch (error) {}
  }

  getFolder(filePath: string): string {
    return filePath.substring(0, filePath.lastIndexOf('/') + 1);
  }

  getFilename(filePath: string): string {
    return filePath.substring(filePath.lastIndexOf('/') + 1).replace(/\?.*/, '');
  }

  async getFileSize(url: string): Promise<number> {
    const binaryContent = await this.readAsBinaryURL(url);

    return binaryContent.length;
  }

  async printBase64Info(url: string) {
    const base64Content = await this.readAsDataURL(url);
    const length = base64Content.length;
    console.log(
      `base64Content (${length}): ${base64Content.substring(0, 40)} .. ${base64Content.substring(length - 20)}`,
    );
  }

  getFilepath(folder: string, filename: string) {
    return this.file.dataDirectory + folder + "/" + filename;
  }

  async readFileAsBase64(folder: string, filename: string): Promise<Nullable<string>> {
    try {
      return await this.file.readAsDataURL(this.file.dataDirectory + folder, filename);
    } catch (error) {
      return null;
    }
  }

  async deleteDirectories(prefix: string) {
    try {
      const directoryEntries = await this.file.listDir(this.file.dataDirectory, '');
      for (const entry of directoryEntries) {
        if (entry.isDirectory && entry.name.startsWith(prefix)) {
          await this.deleteDirectory(entry.name);
        }
      }
    } catch (error) {}
  }
}
