import { LocalDate } from "src/core/shared/date/local-date";
import { Nullable } from "src/core/shared/types/nullable.type";

export type StepPrimitives = {
  id: string;
  experienceId: string;
  language: string;
  order: number;
  name: string;
  duration: number;
  roomName: string;
  floorName: string;
  mediaUrl: string;
  imageUrl: string;
  updatedAt: Nullable<string>;
  missionId: Nullable<string>;
}

export class Step {
  constructor(
    public readonly id: string,
    public readonly experienceId: string,
    public readonly language: string,
    public readonly order: number,
    public readonly name: string,
    public readonly duration: number,
    public readonly roomName: string,
    public readonly floorName: string,
    public readonly mediaUrl: string,
    public readonly imageUrl: string,
    public readonly updatedAt: Nullable<LocalDate>,
    public readonly missionId: Nullable<string>,
  ) {}

  static fromPrimitives(step: StepPrimitives) {
    return new Step(step.id, step.experienceId, step.language, step.order, step.name, step.duration, step.roomName, step.floorName, step.mediaUrl, step.imageUrl, LocalDate.fromIsoString(step.updatedAt), step.missionId);
  }

  formattedDurationOfLanguage(): string {
    const minutes = Math.floor(this.duration / 60);
    const seconds = String(Math.floor(this.duration % 60)).padStart(2, '0');

    return `${minutes}:${seconds}`;
  }

  toPrimitives(): StepPrimitives {
    return {
      id: this.id,
      experienceId: this.experienceId,
      language: this.language,
      order: this.order,
      name: this.name,
      duration: this.duration,
      floorName: this.floorName,
      roomName: this.roomName,
      mediaUrl: this.mediaUrl,
      imageUrl: this.imageUrl,
      updatedAt: this.updatedAt?.atom ?? null,
      missionId: this.missionId,
    };
  }
}
