import { ExperienceStep, ExperienceStepPrimitives } from "src/core/experiences/domain/experience-step";
import { LocalDate } from "src/core/shared/date/local-date";
import { Nullable } from "src/core/shared/types/nullable.type";

export type ExperiencePrimitives = {
  id: string;
  name: { [key: string]: string; }
  availableLanguages: Array<string>;
  steps: Array<ExperienceStepPrimitives>;
  updatedAt: Nullable<string>;
}

export class Experience {
  constructor(
    public readonly id: string,
    public readonly name: { [key: string]: string; },
    public readonly availableLanguages: string[],
    public readonly steps: Array<ExperienceStep>,
    public readonly updatedAt: Nullable<LocalDate>,
  ) {}

  static fromPrimitives(experience: ExperiencePrimitives) {
    const steps = experience.steps.map(step => ExperienceStep.fromPrimitives(step))
    return new Experience(experience.id, experience.name, experience.availableLanguages, steps, LocalDate.fromIsoString(experience.updatedAt));
  }

  nameInLanguage(language: string): string {
    return this.name[language] ?? this.name['es'] ?? this.name[0];
  }

  toPrimitives(): ExperiencePrimitives {
    return {
      id: this.id,
      name: this.name,
      availableLanguages: this.availableLanguages,
      steps: this.steps.map(step => step.toPrimitives()),
      updatedAt: this.updatedAt?.atom ?? null,
    };
  }

  isOlderThan(experience: Experience): boolean {
    if (this.updatedAt === null) {
      return true;
    }
    if (experience.updatedAt === null) {
      return false;
    }

    return this.updatedAt.date.isBefore(experience.updatedAt.date, 'second');
  }
}
