import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'any',
})
export class PlatformService {
  constructor(private platform: Platform) {}

  isCordova(): boolean {
    return this.platform.is('cordova');
  }

  isAndroid(): boolean {
    if (this.isSimulatingDevice()) {
      return false;
    }

    return this.platform.is('android');
  }

  isIos() {
    if (this.isSimulatingDevice()) {
      return false;
    }

    return this.platform.is('ios') || this.platform.is('ipad') || this.platform.is('iphone');
  }

  private isSimulatingDevice() {
    return this.platform.is('mobileweb');
  }

  /**
   * App or Web in mobile device
   */
  isRealMobileDevice(): boolean {
    if (!this.isCordova()) {
      return false;
    }

    // on desktop DEV and simulating device, is NOT a real mobile device
    if (this.platform.is('mobileweb')) {
      return false;
    }

    // To fix platform.is('ipad') not working on some devices
    // const isIpadFix = this.device.model !== null && this.device.model.includes('iPad');
    const isIpadFix = true;
    const isIpad = this.platform.is('ipad') || isIpadFix;

    return isIpad || this.platform.is('ios') || this.platform.is('android');
  }
}
