import { Experience, ExperiencePrimitives } from "./experience";
import { Nullable } from "src/core/shared/types/nullable.type";

export class Experiences {
  constructor(public experiences: Experience[] = []) {}

  toArray(): Experience[] {
    return this.experiences;
  }

  findById(id: string): Nullable<Experience> {
    const experiences = this.experiences.filter(experience => experience.id === id);
    if (experiences.length === 0) {
      return null;
    }

    return experiences[0];
  }

  toPrimitives(): Array<ExperiencePrimitives> {
    return this.experiences.map(experience => experience.toPrimitives());
  }

  ids(): Array<string> {
    return this.experiences.map(experience => experience.id);
  }
}
