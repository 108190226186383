import { StorageService } from "../domain/storage.service";
import { Storage as AngularStorage } from '@ionic/storage-angular';
import { Injectable } from "@angular/core";
import { Nullable } from "src/core/shared/types/nullable.type";

@Injectable({
  providedIn: 'root',
})
export class IonicStorageService extends StorageService {
  private storage: Nullable<AngularStorage> = null;

  constructor(private internalStorage: AngularStorage) {
    super();
    this.init().then().catch();
  }

  async init(): Promise<void> {
    this.storage = await this.internalStorage.create();
  }

  async reset(): Promise<void> {
    if (!this.storage) {
      await this.init();
    }
    if (this.storage === null) {
      return;
    }


    const keysToKeep: string[] = [];
    const allKeys: any[] = await this.storage?.keys();
    const keysToDelete = allKeys.filter((key) => !keysToKeep.includes(key));
    await Promise.all(keysToDelete.map((key) => this.storage?.remove(key)));
  }

  public async ready(): Promise<void> {}

  async keys(): Promise<Array<string>> {
    return this.storage?.keys() ?? [];
  }

  async set(key: string, value: any) {
    if (!this.storage) await this.init();

    await this.storage?.set(key, value);
  }

  async get(key: string): Promise<Nullable<any>> {
    if (!this.storage) await this.init();

    return this.storage?.get(key);
  }

  async remove(key: string): Promise<void> {
    if (!this.storage) await this.init();

    return this.storage?.remove(key);
  }
}
