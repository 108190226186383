export type LoggedUserPrimitives = {
  fullName: string;
  email: string;
};

export class LoggedUser {
  constructor(public readonly fullName: string, public readonly email: string) {}

  static fromPrimitives(primitives: LoggedUserPrimitives) {
    // TODO: Check all primitives, and throw an Exception if these do not fulfill the current contract
    return new this(primitives.fullName, primitives.email);
  }

  toPrimitives(): LoggedUserPrimitives {
    return {
      fullName: this.fullName,
      email: this.email,
    };
  }
}
