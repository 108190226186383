import { Prize, PrizePrimitives } from "src/core/prize/domain/prize";
import { Nullable } from "src/core/shared/types/nullable.type";

export class Prizes {
  constructor(public readonly prizes: Array<Prize> = []) {}

  toArray(): Prize[] {
    return this.prizes;
  }

  findById(id: string): Nullable<Prize> {
    const prizes = this.prizes.filter(prize => prize.id === id);
    if (prizes.length === 0) {
      return null;
    }

    return prizes[0];
  }

  toPrimitives(): Array<PrizePrimitives> {
    return this.prizes.map(prize => prize.toPrimitives());
  }

  ids(): Array<string> {
    return this.prizes.map(prize => prize.id);
  }

  isEmpty(): boolean {
    return this.prizes.length === 0;
  }
}
