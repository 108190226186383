export abstract class TranslatorService {
  public static readonly DEFAULT_LANGUAGE: string = 'es';
  public static readonly AVAILABLE_LANGUAGES: Array<{ id: string; label: string }> = [
    // { id: 'en', label: 'English' },
    { id: 'es', label: 'Español' },
    // { id: 'ca', label: 'Català' },
  ];

  abstract instant(key: string, params?: object): string;

  abstract translate(key: string, params?: object): Promise<string>;

  abstract changeLanguage(language: string): void;

  abstract language(): string;

  abstract defaultLanguage(): string;
}
