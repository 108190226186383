export type PrizePrimitives = {
  id: string;
  type: number;
  title: { [key: string]: string; };
  shortDescription: { [key: string]: string; };
  longDescription: { [key: string]: string; };
  imageUrl: string;
  points: number;
}

export class Prize {
  constructor(
    public readonly id: string,
    public readonly type: number,
    public readonly title: { [key: string]: string; },
    public readonly shortDescription: { [key: string]: string; },
    public readonly longDescription: { [key: string]: string; },
    public readonly imageUrl: string,
    public readonly points: number,
  ) {}

  static fromPrimitives(primitives: PrizePrimitives) {
    return new Prize(
      primitives.id,
      primitives.type,
      primitives.title,
      primitives.shortDescription,
      primitives.longDescription,
      primitives.imageUrl,
      primitives.points,
    );
  }

  getTitle(language: string): string {
    return this.title[language] ?? '';
  }

  getShortDescription(language: string): string {
    return this.shortDescription[language] ?? '';
  }

  getLongDescription(language: string): string {
    // return 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aliquam debitis delectus deserunt dicta harum id ipsa itaque maiores, minus natus nulla quam reiciendis sint temporibus vero vitae voluptate voluptates!';
    return this.longDescription[language] ?? '';
  }

  toPrimitives(): PrizePrimitives {
    return {
      id: this.id,
      type: this.type,
      title: this.title,
      shortDescription: this.shortDescription,
      longDescription: this.longDescription,
      imageUrl: this.imageUrl,
      points: this.points,
    };
  }
}
