import { Initializable } from "src/core/shared/initializer/domain/initializable";
import { Storable } from "src/core/shared/storage/domain/storable";
import { BehaviorSubject, Observable } from "rxjs";
import { StorageService } from "src/core/shared/storage/domain/storage.service";
import { Nullable } from "src/core/shared/types/nullable.type";
import { Injectable } from "@angular/core";
import { Prizes } from "src/core/prize/domain/prizes";
import { Prize, PrizePrimitives } from "src/core/prize/domain/prize";

@Injectable({
  providedIn: 'root',
})
export class PrizeService implements Storable, Initializable {
  private readySubject = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  ready$: Observable<boolean> = this.readySubject.asObservable();

  private readonly KEY_PRIZES = 'prizes';

  private prizes: Nullable<Prizes> = null;

  constructor(private storage: StorageService) {}

  async init() {
    await this.initFromStorage();

    this.readySubject.next(true);
  }

  async clear() {
    await this.clearPrizes();
  }

  async savePrizes(prizes: Prizes) {
    this.prizes = prizes;
    const prizesPrimitives = prizes.toPrimitives();
    await this.storage.set(this.KEY_PRIZES, prizesPrimitives);
  }

  getPrize(id: string): Nullable<Prize> {
    if (!this.prizes) {
      return null;
    }

    return this.prizes.findById(id);
  }

  getAll(): Prizes {
    return this.prizes ?? new Prizes();
  }

  getAffordablePrizes(points: number): Prizes {
    const prizes = this.prizes ?? new Prizes();
    const affordablePrizes = prizes.toArray().filter(prize => prize.points <= points);

    return new Prizes(affordablePrizes);
  }

  private async initFromStorage(): Promise<void> {
    await this.initPrizes();
  }

  private async initPrizes() {
    const prizes = await this.storage.get(this.KEY_PRIZES);
    this.prizes = prizes
      ? new Prizes(prizes.map((primitives: PrizePrimitives) => Prize.fromPrimitives(primitives)))
      : new Prizes();
  }

  private async clearPrizes() {
    this.prizes = null;
    await this.storage.remove(this.KEY_PRIZES);
  }
}
