import { ExtraContent } from "src/core/extra-content/domain/extra-content";

export class ExtraContents {
  constructor(private readonly data: Array<ExtraContent> = []) {}

  onlyExclusive(): ExtraContents {
    return new ExtraContents(this.data.filter(c => c.isExclusive()));
  }

  onlyInformative(): ExtraContents {
    return new ExtraContents(this.data.filter(c => c.isInformative()));
  }

  toArray(): Array<ExtraContent> {
    return this.data;
  }
}
