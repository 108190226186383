import { UnitHelper } from "src/core/shared/units/unit-helper";

export class StepMedia {
  constructor(
    public readonly stepId: string,
    public readonly url: string,
    public readonly language: string,
    public readonly content: Blob,
  ) {}

  size(): string {
    return UnitHelper.humanReadable(this.content.size || 0);
  }
}
