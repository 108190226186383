import { Step } from "src/core/step/domain/step";

export class Steps {
  constructor(public readonly steps: Array<Step>) {}

  toArray(): Array<Step> {
    return this.steps;
  }

  toPrimitives() {
    return this.steps.map(step => step.toPrimitives());
  }
}
