export type ExperienceStepPrimitives = {
  id: string;
  order: number;
  name: {
    [key: string]: string;
  };
  duration: {
    [key: string]: number;
  };
  iconUrl: string;
}

export class ExperienceStep {
  constructor(
    public readonly id: string,
    public readonly order: number,
    public readonly name: { [key: string]: string; },
    public readonly duration: { [key: string]: number; },
    public readonly iconUrl: string,
  ) {}

  static fromPrimitives(primitives: ExperienceStepPrimitives) {
    return new ExperienceStep(primitives.id, primitives.order, primitives.name, primitives.duration, primitives.iconUrl);
  }

  nameInLanguage(language: string): string {
    return this.name[language] ?? this.name['es'] ?? this.name[0];
  }

  durationOfLanguage(language: string): number {
    return this.duration[language] ?? 0;
  }

  formattedDurationOfLanguage(language: string) {
    const duration = this.durationOfLanguage(language);
    const minutes = Math.floor(duration / 60);
    const seconds = String(Math.floor(duration % 60)).padStart(2, '0');

    return `${minutes}:${seconds}`;
  }

  toPrimitives(): ExperienceStepPrimitives {
    return {
      id: this.id,
      order: this.order,
      name: this.name,
      duration: this.duration,
      iconUrl: this.iconUrl,
    };
  }
}
