import { Nullable } from "src/core/shared/types/nullable.type";

export class JWT {
  readonly decodedToken: { [key: string]: any };

  private readonly NAME = 'name';
  private readonly GIVEN_NAME = 'given_name';
  private readonly FAMILY_NAME = 'family_name';
  private readonly EMAIL = 'email';
  private readonly TENANT_ID = 'tid';
  private readonly EXPIRATION_TIME = 'exp';
  private readonly NOT_BEFORE = 'nbf';
  private readonly ISSUED_AT = 'iat';
  private readonly ISSUER = 'iss';
  private readonly SUBJECT = 'sub';
  private readonly AUDIENCE = 'aud';
  private readonly AUTHENTICATION_CONTEXT_REFERENCE = 'acr';
  private readonly NONCE = 'nonce';
  private readonly AUTH_TIME = 'auth_time';
  private readonly VERSION = 'ver';

  constructor(public readonly token: string) {
    this.decodedToken = this.parseJwt(this.token);
  }

  static createUnsignedJwt(payload: object): string {
    const base64UrlEncode = (str: string): string => {
      return window.btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    };

    const header = {
      alg: "none",
      typ: "JWT",
    };

    const encodedHeader = base64UrlEncode(JSON.stringify(header));
    const encodedPayload = base64UrlEncode(JSON.stringify(payload));

    return `${encodedHeader}.${encodedPayload}.`;
  }

  tid(): string {
    return this.fieldData(this.TENANT_ID) as string ?? '';
  }

  name(): string {
    return this.fieldData(this.NAME) as string ?? '';
  }

  email(): string {
    return this.fieldData(this.EMAIL) as string ?? '';
  }

  expirationTime(): Nullable<number> {
    return this.fieldData(this.EXPIRATION_TIME) as number ?? null;
  }

  isExpired() {
    const exp = this.expirationTime();
    if (!exp) {
      return true;
    }

    const now = Math.floor(Date.now() / 1000);
    return exp < now;
  }

  private fieldData(field: string): Nullable<string | number> {
    return this.decodedToken[field] ?? null;
  }

  private parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }
}
