import { Storable } from "src/core/shared/storage/domain/storable";
import { Initializable } from "src/core/shared/initializer/domain/initializable";
import { BehaviorSubject, Observable } from "rxjs";
import { StorageService } from "src/core/shared/storage/domain/storage.service";
import { PrivacyPolicy } from "src/core/privacy-policy/domain/privacy-policy";
import { Nullable } from "src/core/shared/types/nullable.type";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class PrivacyPolicyService implements Storable, Initializable {
  private readySubject = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  ready$: Observable<boolean> = this.readySubject.asObservable();

  private readonly KEY_PRIVACY_POLICY = 'privacy_policy';
  private data: Nullable<PrivacyPolicy> = null;

  constructor(private storage: StorageService) {}

  async init() {
    await this.initFromStorage();

    this.readySubject.next(true);
  }

  async clear() {
    this.data = null;
    await this.storage.remove(this.KEY_PRIVACY_POLICY);
  }

  getPrivacyPolicy(): Nullable<PrivacyPolicy> {
    return this.data;
  }

  async save(privacyPolicy: PrivacyPolicy) {
    this.data = privacyPolicy;
    await this.storage.set(this.KEY_PRIVACY_POLICY, privacyPolicy.toPrimitives());
  }

  isAnalyticsCookiesEnabled(): boolean {
    if (!this.data) {
      return false;
    }

    return this.data.isAnalyticsCookiesEnabled() ?? false;
  }

  isAdvertisingCookiesEnabled(): boolean {
    if (!this.data) {
      return false;
    }

    return this.data.isAdvertisingCookiesEnabled() ?? false;
  }

  private async initFromStorage(): Promise<void> {
    const data = await this.storage.get(this.KEY_PRIVACY_POLICY);
    this.data = data
      ? PrivacyPolicy.fromPrimitives(data)
      : PrivacyPolicy.default();
  }
}
