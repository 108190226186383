import { Nullable } from "src/core/shared/types/nullable.type";
import { MultiLanguageString } from "src/core/shared/multi-language/multi-language-string";
import { QuizQuestion, QuizQuestionPrimitives } from "src/core/quiz/domain/quiz-question";

export type QuizPrimitives = {
  id: string;
  name: { [key: string]: string; };
  description: { [key: string]: string; };
  questions: Array<QuizQuestionPrimitives>;
};

export class Quiz {
  constructor(
    public readonly id: string,
    public readonly name: MultiLanguageString,
    public readonly description: MultiLanguageString,
    public readonly questions: Array<QuizQuestion>,
  ) {}

  static fromPrimitives(primitives: QuizPrimitives): Nullable<Quiz> {
    if (!primitives) {
      return null;
    }

    const questions: Array<QuizQuestion> = primitives.questions.map(o => {
      return QuizQuestion.fromPrimitives({
        id: o.id,
        text: o.text,
        answers: o.answers,
      });
    }).filter((q): q is QuizQuestion => q !== null);

    return new this(
      primitives.id,
      new MultiLanguageString(primitives.name),
      new MultiLanguageString(primitives.description),
      questions,
    );
  }

  toPrimitives(): QuizPrimitives {
    return {
      id: this.id,
      name: this.name.toPrimitives(),
      description: this.description.toPrimitives(),
      questions: this.questions.map(o => o.toPrimitives()),
    };
  }
}
