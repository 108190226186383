import { AuthRepository } from "src/core/authentication/domain/auth.repository";
import { ApiService } from "src/core/api/domain/api.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { lastValueFrom } from "rxjs";
import { Injectable } from "@angular/core";
import { ApiGetAccessTokenResponse } from "src/core/authentication/infrastructure/api-get-access-token-response";
import { JWT } from "src/core/authentication/domain/jwt";

@Injectable({
  providedIn: "any",
})
export class HttpAuthRepository extends AuthRepository {
  constructor(
    private readonly apiService: ApiService,
    private readonly http: HttpClient,
  ) {
    super();
  }

  override async getAccessToken(authorizationCode: string): Promise<{ apiToken: JWT, refreshToken: string }> {
    const api = this.apiService.currentApi();
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const body = new HttpParams()
      .set('client_id', api.clientId)
      .set('client_secret', api.clientSecret)
      .set('grant_type', 'authorization_code')
      .set('scope', api.scopes.join(' '))
      .set('code', authorizationCode);

    const response = await lastValueFrom(this.http.post<ApiGetAccessTokenResponse>(this.apiService.getAccessTokenUrl(), body.toString(), { headers }));
    const apiToken = new JWT(response.access_token);
    const refreshToken = response.refresh_token;

    return { apiToken, refreshToken };
  }
}
